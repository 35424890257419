<template>
  <!-- 设置停报客户 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="提示" v-model="dialogVisible" width="25%">
    <el-form :model="form" label-width="100px" label-position="right" size="small">
      <div><span style="color:red">*</span> 请输入软件密码确认身份：</div>
      <div style="margin:10px 0 20px">
        <el-input v-model="form.password" style="width:100%;" size="small"></el-input>
      </div>
      <div><span style="color:red">*</span>请输入停报原因：</div>
      <div style="margin-top:10px">
        <el-input v-model="form.remark" style="width:100%;" size="small" :rows="5" type="textarea"></el-input>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="stopSure" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { changeStatusNew } from "@/api/company";
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        remark: "",
        password: "",
      },
      coms: [],
    };
  },
  methods: {
    init(rows) {
      this.coms = rows;
      this.form = {
        remark: "",
        password: "",
      };
      this.dialogVisible = true;
    },
    stopSure() {
      if (!this.form.password || !this.form.remark) {
        this.$qzfMessage("请填写完整", 1);
        return;
      }
      this.coms.map((v) => {
        v.status = 3;
        v.remark = this.form.remark;
        if (this.from == 'automatic') {
          v.id = v.comId
        }
      });
      let param = {
        eaCompany1: this.coms,
        password: this.form.password,
      };
      changeStatusNew(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
          this.dialogVisible = false
          this.$bus.emit("stopReportingUpdate");
          this.$emit("success");
        }
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
