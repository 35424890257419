<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    title="修改税种年报申报周期"
    v-model="dialogTableVisible"
    width="550px"
  >
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="税种" prop="projectName">
        <el-select
          v-model="form.projectName"
          placeholder="请选择税种"
          size="small"
          filterable
        >
          <el-option
            v-for="item in collection"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申报周期" prop="month">
        <el-select
          v-model="form.month"
          placeholder="请选择申报周期"
          size="small"
        >
          <el-option
            v-for="item in type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveSetting()" :loading="btnLoading"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { saveTaxCategoryYear } from "@/api/taxCalculation";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      form: {
        comIds: [],
        projectName: "",
        month: "",
      },
      collection: [
        {
          value: "印花税（营业账簿）",
          label: "印花税（营业账簿）",
        },

        {
          value: "工会经费",
          label: "工会经费",
        },

        {
          value: "残疾人就业保障金",
          label: "残疾人就业保障金",
        },
        {
          value: "城市生活垃圾处置费",
          label: "城市生活垃圾处置费",
        },
        {
          value: "建设行政事业性收费收入",
          label: "建设行政事业性收费收入",
        },
      ],
      type: [
        {
          value: "01",
          label: "一月",
        },
        {
          value: "02",
          label: "二月",
        },
        {
          value: "03",
          label: "三月",
        },
        {
          value: "04",
          label: "四月",
        },
        {
          value: "05",
          label: "五月",
        },
        {
          value: "06",
          label: "六月",
        },
        {
          value: "07",
          label: "七月",
        },
        {
          value: "08",
          label: "八月",
        },
        {
          value: "09",
          label: "九月",
        },
        {
          value: "10",
          label: "十月",
        },
        {
          value: "11",
          label: "十一月",
        },
        {
          value: "12",
          label: "十二月",
        },
      ],
      rules: {
        projectName: [
          { required: true, message: "请选择税种", trigger: "change" },
        ],
        month: [
          { required: true, message: "请选择申报周期", trigger: "change" },
        ],
      },
      btnLoading:false
    };
  },
  methods: {
    init(item,type) {
      let ids = [];
      if(type=='财务工厂'){
        item.map((v) => {
          ids.push(v.comId);
        });
      }else{
        item.map((v) => {
          ids.push(v.id);
        });
      }
      this.form = {
        comIds: ids,
        projectName: "",
        month: "",
      };
      this.dialogTableVisible = true;
    },
    saveSetting() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          saveTaxCategoryYear(this.form).then((res) => {
            this.btnLoading = false
            if (res.data.msg == "success") {
              this.$qzfMessage("请稍后查看",1);
              this.dialogTableVisible = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
